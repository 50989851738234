import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import moment from "moment";

export interface ReservedServerProps {
	privateServerId: string;

	serverName: string;
	serverDescription: string;
	serverCreationTime: number;

	ownerName: string;
	ownerId: number;
	ownerProfileUrl: string;

	socialLinks: {
		Discord: string;
		YouTube: string;
		X: string;
	};

	currentPlayers: number;
	maxPlayers: number;
}

export default function ReservedServer(props: ReservedServerProps) {
	const [showModal, setShowModal] = useState(false);

	const startedText = useMemo(() => {
		const date = new Date(0);
		date.setUTCSeconds(props.serverCreationTime);
		return moment(date).fromNow();
	}, [props.serverCreationTime]);

	const isFull = props.currentPlayers >= props.maxPlayers;

	return (
		<Card key={props.privateServerId} role="button" className="shadow border-0 mb-3 fw-bold">
			<Card.Body onClick={() => setShowModal(true)}>
				<Row>
					<Col xs={12} className="mb-1">
						{props.serverName}
					</Col>
					<Col xs={6} className="d-flex align-items-center">
						<Image src={props.ownerProfileUrl} width={30} height={30} className="me-2" />
						{props.ownerName}
					</Col>
					<Col xs={6} className="d-flex align-items-center">
						<i className="bi bi-people-fill fs-5 me-2" />
						{props.currentPlayers}/{props.maxPlayers}
					</Col>
				</Row>
			</Card.Body>

			<Modal show={showModal} centered size="lg" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{props.serverName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={4}>
							<div className="fw-bold">Server Owner</div>
							<div>
								<a
									className="link"
									href={`https://www.roblox.com/users/${props.ownerId}/profile`}
									target="_blank"
									rel="noreferrer">
									<Image src={props.ownerProfileUrl} width={18} height={18} className="me-1" />
									{props.ownerName}
								</a>
							</div>
						</Col>

						<Col xs={4}>
							<div className="fw-bold">Server Startup</div>
							<div>{startedText}</div>
						</Col>
						<Col xs={4}>
							<div className="fw-bold">Player Count</div>
							<div>
								{props.currentPlayers}/{props.maxPlayers}
							</div>
						</Col>
						<Col xs={12} className="mt-3">
							<div className="fw-bold">Description</div>
							<div>{props.serverDescription}</div>
						</Col>

						{Object.values(props.socialLinks).some((link) => link !== "") && (
							<Col xs={12} className="mt-3">
								<div className="fw-bold">Social Links</div>
								<Row>
									{props.socialLinks.Discord && (
										<Col xs={4}>
											<a
												className="link d-flex align-items-center fw-bold text-primary"
												href={`https://discord.com/invite/${props.socialLinks.Discord}`}
												target="_blank"
												rel="noreferrer">
												<i className="bi bi-discord fs-3 me-2" />
												Discord
											</a>
										</Col>
									)}
									{props.socialLinks.X && (
										<Col xs={4}>
											<a
												className="link d-flex align-items-center fw-bold"
												href={`https://x.com/${props.socialLinks.X}`}
												target="_blank"
												rel="noreferrer">
												<i className="bi bi-twitter-x fs-3 me-2" />
												Twitter
											</a>
										</Col>
									)}
									{props.socialLinks.YouTube && (
										<Col xs={4}>
											<a
												className="link d-flex align-items-center fw-bold text-danger"
												href={`https://youtube.com/@${props.socialLinks.YouTube}`}
												target="_blank"
												rel="noreferrer">
												<i className="bi bi-youtube fs-3 me-2" />
												YouTube
											</a>
										</Col>
									)}
								</Row>
							</Col>
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="success"
						disabled={isFull}
						href={`https://www.roblox.com/games/start?placeId=7711635737&launchData=reservedServerId=${props.privateServerId}`}
						target="_blank">
						Join Server <i className="bi bi-play-circle" />
					</Button>
				</Modal.Footer>
			</Modal>
		</Card>
	);
}
