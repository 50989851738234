import * as Yup from "yup";
import { Formik } from "formik";
import { Link, NavLink } from "react-router-dom";
import { ruleViolations } from "../modules/ruleViolations";
import { serialize } from "object-to-formdata";
import { useCallback, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Header from "../components/header";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios, { AxiosProgressEvent } from "axios";
import useAuthorization from "../hooks/useAuthorization";

function getFileListSize(fileList: FileList) {
	let size = 0;

	Array.from(fileList).forEach((file) => {
		size += file.size;
	});

	return size / (1024 * 1024);
}

const schema = Yup.object().shape({
	username: Yup.string().required("Username is required."),
	ruleViolations: Yup.array(Yup.string()).min(1, "There must be at least one rule violation."),
	evidence: Yup.mixed()
		.required("Evidence is required.")
		.test("fileExists", "Evidence is required.", (value) => {
			const fileList = value as FileList | undefined;
			if (!fileList) return true;

			return fileList.length > 0;
		})
		.test("fileSize", "Evidence files can not be larger than 100MB.", (value) => {
			const fileList = value as FileList | undefined;
			if (!fileList) return true;

			return getFileListSize(fileList) < 100;
		}),
});

export default function PlayerReport() {
	const [reportSent, setReportSent] = useState(false);

	const [sendingProgress, setSendingProgress] = useState<AxiosProgressEvent>();

	const [error, setError] = useState("");
	const [errorDescription, setErrorDescription] = useState("");
	const [errorOccurred, setErrorOccurred] = useState(false);

	const handleError = useCallback((error: string, errorDescription: string) => {
		console.error(`An error occurred while authenticating: ${error} - ${errorDescription}`);

		setError(error);
		setErrorDescription(errorDescription);
		setErrorOccurred(true);
	}, []);

	const sendReport = useCallback(async (code: string, value: object) => {
		const formData = serialize(value, {
			noAttributesWithArrayNotation: true,
			noFilesWithArrayNotation: true,
		});

		const response = await axios.post(
			`https://api.emergency-hamburg.com/public/report-player?code=${code}`,
			formData,
			{
				onUploadProgress: (progressEvent) => setSendingProgress(progressEvent),
				validateStatus: () => true,
			},
		);

		setSendingProgress(undefined);

		if (response.status === 200) {
			setReportSent(true);
		} else {
			if (response.status === 429) {
				setError("Player Report Limit Exceeded");
				setErrorDescription(
					"You recently submitted a player report. If necessary, you may send another report later.",
				);
				setErrorOccurred(true);

				return;
			}

			console.error(
				`An error code ${response.status} occurred while sending the player report: ${response.statusText}`,
			);

			setError(`Player Report Error: ${response.status}`);
			setErrorDescription(response.statusText);
			setErrorOccurred(true);
		}
	}, []);

	const authorize = useAuthorization(sendReport, handleError);

	return (
		<div>
			<Header />
			<Container>
				<h4>Player Report</h4>
				<Formik
					validationSchema={schema}
					onSubmit={authorize}
					validateOnMount
					initialValues={{
						username: "",
						additionalInformation: "",
						ruleViolations: [] as string[],
						evidence: undefined as undefined | FileList,
					}}>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched,
						values,
						errors,
						touched,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Rule Violator Roblox Username</Form.Label>
								<Form.Text>
									Please provide us the Roblox username of the rule violator, not the display name.
								</Form.Text>
								<InputGroup hasValidation>
									<InputGroup.Text>@</InputGroup.Text>
									<Form.Control
										type="text"
										placeholder="Username"
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={touched.username && !!errors.username}
									/>
									<Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Rule Violations</Form.Label>
								<Form.Text>
									Please select the rule violations the user committed. We do not moderate insults
									or other chat issues, please contact{" "}
									<a target="_blank" href="https://www.roblox.com/support" rel="noreferrer">
										Roblox support
									</a>{" "}
									for that. Note that fail roleplay and teaming are not rule violations.
								</Form.Text>

								{ruleViolations.map((violation, index) => (
									<div key={index}>
										<Form.Check
											className="mb-0 mt-2"
											name="ruleViolations"
											onChange={handleChange}
											onBlur={handleBlur}
											label={violation.name}
											value={violation.name}
											isInvalid={touched.ruleViolations && !!errors.ruleViolations}
										/>
										<Form.Text> {violation.description} </Form.Text>
									</div>
								))}
								{touched.ruleViolations && errors.ruleViolations && (
									<Form.Text className="d-block text-danger">{errors.ruleViolations}</Form.Text>
								)}
							</Form.Group>

							{values.ruleViolations.length > 0 &&
							ruleViolations.some(
								(rule) => rule.type === "none" && values.ruleViolations.includes(rule.name),
							) ? (
								<Alert className="mt-2" variant="warning">
									<h5>Report cannot be sent</h5>
									Fail roleplay and teaming are not rule violations. For a better roleplay
									experience, you can join a private roleplay server{" "}
									<Link to="/private-servers">here</Link>.
								</Alert>
							) : values.ruleViolations.length > 0 &&
							  ruleViolations.some(
									(rule) => rule.type === "roblox" && values.ruleViolations.includes(rule.name),
							  ) ? (
								<>
									<Alert className="mt-2" variant="success">
										One of the rule violations falls within the responsibilities of Roblox. On the
										Roblox support page, select "Moderation" and "Report User Breaking Rules" in
										issue details and provide the necessary information.
									</Alert>
									<Button
										className="mb-3"
										href="https://www.roblox.com/support"
										target="_blank"
										variant="success">
										Continue Report
									</Button>
								</>
							) : (
								<>
									<Form.Group className="mt-3">
										<Form.Label className="d-block mb-0">Evidence</Form.Label>
										<Form.Text>
											Please provide us with video or image evidence regarding your report.
										</Form.Text>
										<Form.Control
											type="file"
											multiple
											accept="video/*,image/*"
											onChange={(event) => {
												const target = event.target as HTMLInputElement;

												setFieldTouched("evidence");
												setFieldValue("evidence", target.files);
											}}
											isInvalid={touched.evidence && !!errors.evidence}
										/>
										<Form.Control.Feedback type="invalid">{errors.evidence}</Form.Control.Feedback>
									</Form.Group>

									<Form.Group className="mt-3">
										<Form.Label className="d-block mb-0">Additional Information</Form.Label>
										<Form.Text>
											Please provide us with any additional information regarding your report, e.g.
											the timestamp in a video where the rule violation occurs.
										</Form.Text>
										<Form.Control
											as="textarea"
											name="additionalInformation"
											value={values.additionalInformation}
											onChange={handleChange}
											onBlur={handleBlur}
											rows={3}
										/>
									</Form.Group>

									<Form.Group className="mt-3 mb-3">
										<Form.Label className="d-block mb-2">
											A verification popup will be shown where you have to verify your roblox
											account to complete your report.
											<b> Submitting false or spam reports will result in a game ban.</b>
										</Form.Label>
										<Button type="submit" variant="success">
											Submit Report
										</Button>
									</Form.Group>
								</>
							)}
						</Form>
					)}
				</Formik>
			</Container>

			<Modal
				show={errorOccurred}
				centered
				backdrop="static"
				keyboard={false}
				onHide={() => setErrorOccurred(false)}>
				<Modal.Header closeButton>
					<Modal.Title>An error occurred while sending your report.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Error: {error}</p>
					<p>Error description: {errorDescription}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setErrorOccurred(false)}>Close</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={reportSent} centered backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Your report has been sent.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Your report has been sent and will be reviewed by one of our moderators soon.</p>
				</Modal.Body>
				<Modal.Footer>
					<NavLink to="/">
						<Button>Close</Button>
					</NavLink>
				</Modal.Footer>
			</Modal>

			<Modal show={!!sendingProgress} centered backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Your report is sending.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{Math.round(sendingProgress?.estimated ?? 0)} seconds remaining.</p>
					<ProgressBar
						animated
						variant="success"
						now={((sendingProgress?.loaded ?? 0) / (sendingProgress?.total ?? 1)) * 100}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}
