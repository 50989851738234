import * as Yup from "yup";
import { Formik } from "formik";
import { NavLink } from "react-router-dom";
import { serialize } from "object-to-formdata";
import { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Header from "../components/header";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios, { AxiosProgressEvent } from "axios";
import useAuthorization from "../hooks/useAuthorization";

type BanReturnReason = "notBanned" | "ignoredBanType" | "minDuration";

const schema = Yup.object().shape({
	banReason: Yup.string().required("Ban reason is required."),
	unbanExplanation: Yup.string().required("Unban explanation is required."),
});

export default function UnbanRequest() {
	const [unbanRequestSent, setUnbanRequestSent] = useState(false);

	const [sendingProgress, setSendingProgress] = useState<AxiosProgressEvent>();

	const [error, setError] = useState("");
	const [errorDescription, setErrorDescription] = useState("");
	const [errorOccurred, setErrorOccurred] = useState(false);

	const handleError = useCallback((error: string, errorDescription: string) => {
		console.error(`An error occurred while authenticating: ${error} - ${errorDescription}`);

		setError(error);
		setErrorDescription(errorDescription);
		setErrorOccurred(true);
	}, []);

	const sendUnbanRequest = useCallback(async (code: string, value: object) => {
		const formData = serialize(value, {
			noAttributesWithArrayNotation: true,
			noFilesWithArrayNotation: true,
		});

		const response = await axios.post(
			`https://api.emergency-hamburg.com/public/unban-request?code=${code}`,
			formData,
			{
				onUploadProgress: (progressEvent) => setSendingProgress(progressEvent),
				validateStatus: () => true,
			},
		);

		setSendingProgress(undefined);

		if (response.status === 200) {
			setUnbanRequestSent(true);
		} else {
			if (response.status === 429) {
				setError("Unban Request Limit Exceeded");
				setErrorDescription("You recently submitted an unban request.");

				setErrorOccurred(true);
				return;
			}

			if (response.status === 403) {
				if (response.data === ("ignoredBanType" satisfies BanReturnReason)) {
					setError("Unban Request rejected");
					setErrorDescription(
						"You were banned by an automated anti exploit which has a high accuracy. These bans are not appealable.",
					);
					setErrorOccurred(true);
					return;
				}

				if (response.data === ("minDuration" satisfies BanReturnReason)) {
					setError("Unban Request rejected");
					setErrorDescription("You will be unbanned in the next 24 hours.");
					setErrorOccurred(true);
					return;
				}

				if (response.data === ("notBanned" satisfies BanReturnReason)) {
					setError("Not banned");
					setErrorDescription(
						"The account that you selected in verification is not banned. We cannot process your unban request without the correct account. If you are unable to join with this account, you might have been banned on another account.",
					);
					setErrorOccurred(true);
					return;
				}
			}

			console.error(
				`An error code ${response.status} occurred while sending the unban request: ${response.statusText}`,
			);

			setError(`Unban Request Error: ${response.status}`);
			setErrorDescription(response.statusText);
			setErrorOccurred(true);
		}
	}, []);

	const authorize = useAuthorization(sendUnbanRequest, handleError);

	return (
		<div>
			<Header />
			<Container>
				<h4>Unban Request</h4>
				<Formik
					validationSchema={schema}
					onSubmit={authorize}
					validateOnMount
					initialValues={{
						banReason: "",
						unbanExplanation: "",
					}}>
					{({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Ban Reason</Form.Label>
								<Form.Text>
									Please provide us the ban reason shown when you try joining the game.
								</Form.Text>
								<InputGroup hasValidation>
									<InputGroup.Text>Reason:</InputGroup.Text>
									<Form.Control
										type="text"
										name="banReason"
										value={values.banReason}
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={touched.banReason && !!errors.banReason}
									/>
									<Form.Control.Feedback type="invalid">{errors.banReason}</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mt-3">
								<Form.Label className="d-block mb-0">Unban Explanation</Form.Label>
								<Form.Text>
									Please provide us with an explanation why you were wrongfully banned. Note that
									exploiters will not be unbanned.
								</Form.Text>
								<Form.Control
									as="textarea"
									name="unbanExplanation"
									value={values.unbanExplanation}
									onChange={handleChange}
									onBlur={handleBlur}
									rows={3}
									isInvalid={touched.unbanExplanation && !!errors.unbanExplanation}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.unbanExplanation}
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mt-3 mb-3">
								<Form.Label className="d-block mb-2">
									A verification popup will be shown where you have to verify your roblox account to
									complete your unban request. Please note that
									<b> you have to select your banned account</b> - otherwise, we cannot process your
									unban request.
								</Form.Label>
								<Button type="submit" variant="success">
									Submit Unban Request
								</Button>
							</Form.Group>
						</Form>
					)}
				</Formik>
			</Container>

			<Modal
				show={errorOccurred}
				centered
				backdrop="static"
				keyboard={false}
				onHide={() => setErrorOccurred(false)}>
				<Modal.Header closeButton>
					<Modal.Title>An error occurred while sending your unban request.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Error: {error}</p>
					<p>Error description: {errorDescription}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setErrorOccurred(false)}>Close</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={unbanRequestSent} centered backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Your unban request has been sent.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Your unban request has been sent and will be reviewed by one of our moderators soon.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<NavLink to="/">
						<Button>Close</Button>
					</NavLink>
				</Modal.Footer>
			</Modal>

			<Modal show={!!sendingProgress} centered backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>Your unban request is sending.</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{Math.round(sendingProgress?.estimated ?? 0)} seconds remaining.</p>
					<ProgressBar
						animated
						variant="success"
						now={((sendingProgress?.loaded ?? 0) / (sendingProgress?.total ?? 1)) * 100}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
}
